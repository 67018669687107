*{
  box-sizing: border-box;
}

body{
  padding: 0 20px;
}
.container{
  max-width: 700px;
  margin: 0 auto;
}


header{
  padding: 30px 0;
  @media screen and (max-width: 900px){
    &.is-loggedIn{
      padding-top: 60px;
    }
  }
  nav{
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid black;
    padding-bottom: 30px;
  }
  .menu{
    list-style-type: none;
    margin: 0;
    padding-left: 20px;
    a{
      font-size: 20px;
    }
    li{
      display: inline-block;
      margin-left: 30px;
    }
  }
}

a{
  color: #363760;
  text-decoration: underline;
  &:hover, &:focus{
    text-decoration: none;
  }
}

p{
  line-height: 1.3;
}

.logo{
  color: black;
  font-size: 30px;
  text-decoration: none;
  font-weight: bold;
}

.signOut{
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding: 8px 10px;
  font-weight: bold;
}

p{
  margin-top: 0;
}

.cols{
  @media screen and (min-width: 1000px){
    column-count: 2;
    column-gap: 20px;
  }

}

footer{
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 50px;
  .container{
    padding-top: 20px;
    border-top: 1px solid #ccc;
  }
}
