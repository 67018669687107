
@import "variables";

img{
  max-width: 100%;
}

// .listing{
//   .image{
//     max-width: 600px;
//     margin: auto;
//   }
// }

.post{
  white-space: pre-line;
  position: relative;
  &:not(:last-child){
    border-bottom: 2px solid #ececec;
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
  *:last-child{
    margin-bottom: 0;
  }
  .actions{
    float: right;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    margin-left: 20px;
    font-weight: bold;
    min-width: 150px;
    ul{
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
      margin-top: 5px;
      li{
        margin-bottom: 0;
      }
      button{
        padding: 0;
      }
    }
    button{
      text-align: left;
      font-weight: bold;
      padding: 8px 10px;
      &.error{
        color: $error-color;
        background-color: transparent;
        &:hover, &:focus{
          background-color: $error-color;
          color: #fff;
        }
      }
    }
    @media screen and (min-width: 1000px){
      // max-width: 90px;
      position: absolute;
      left: 100%;
      top: -8px;
      text-align: left;
    }
    // @media screen and (min-width: 1340px){
    //   max-width: none;
    // }
  }
}
