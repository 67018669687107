@import "variables";

.progress{
  position: relative;
  height: 30px;
  width: 300px;
  border: 2px solid black;
  margin-top: 20px;
  &-bar{
    position: absolute;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    background-color: black;
  }
}




textarea{
  width: 100%;
  height: 200px;
}

label{
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}
.instructions{
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
}

input{
  display: block;
}

.input{
  display: block;
  margin-bottom: 20px;

  .image{
    display: inline-block;
    max-width: 33.33%;
  }

}

button{
  cursor: pointer;
  border: 0;
}

button[type="submit"], .button{
  background-color: black;
  color: white;
  font-size: 24px;
  margin-top: 20px;
  padding: 10px 20px;
  font-weight: bold;
}


.intro{
  margin-bottom: 40px;
}


.message{
  font-weight: bold;
  background-color: black;
  color: white;
  font-weight: bold;
  padding: 15px 20px;
  margin-top: 20px;
  display: inline-block;
  &.error{
    background-color: $error-color;
  }
  &.success{
    background-color: $success-color;
  }
  a{
    color: inherit;
  }
}


textarea{
  font-family: inherit;
  padding: 20px;
  font-size: 18px;
  max-width: 100%;
  border: 1px solid black;
}
